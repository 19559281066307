import React from 'react'
import { Z_INDEX } from 'theme'
import styled from 'styled-components/macro'

export const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string }>`
  position: relative;
  margin-top: ${({ margin }) => margin ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth ?? '480px'};
  width: 100%;
  color: white;
  background-image: linear-gradient(to right, #000159, #205db3);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 480px;
  margin-left: 20px;
  border-radius: 25px;
  z-index: ${Z_INDEX.deprecated_content};
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: auto;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
