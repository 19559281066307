import { ParsedQs, parse } from 'qs'

export function parsedQueryString(search?: string): ParsedQs {
  if (!search) {
    // react-router-dom places search string in the hash
    //const hash = window.location.hash
    const hash = '?inputCurrency=ETH&outputCurrency=0x5919dea604631016c15c805e3d948a0384879892'
    search = hash.substr(hash.indexOf('?'))
  }
  return search && search.length > 1 ? parse(search, { parseArrays: false, ignoreQueryPrefix: true }) : {}
}

export default function useParsedQueryString(): ParsedQs {
  return {
    inputCurrency: 'ETH',
    outputCurrency: '0x5919dea604631016c15c805e3d948a0384879892',
  }
}
