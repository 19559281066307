import { RowBetween, RowFixed } from '../Row'

import { Percent } from '@uniswap/sdk-core'
import SettingsTab from '../Settings'
import { TYPE } from '../../theme'
import styled from 'styled-components/macro'

const StyledSwapHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function SwapHeader({
  allowedSlippage,
  includeSettings,
  text,
}: {
  allowedSlippage: Percent
  includeSettings: boolean
  text: string
}) {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <TYPE.white fontWeight={500} fontSize={16} style={{ marginRight: '8px' }}>
            {}
          </TYPE.white>
        </RowFixed>
        {includeSettings && (
          <RowFixed>
            <SettingsTab placeholderSlippage={allowedSlippage} />
          </RowFixed>
        )}
      </RowBetween>
    </StyledSwapHeader>
  )
}
